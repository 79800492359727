<template>
  <div class="main-content">
    <div class="main-table-wrapper main-content-wrapp">
      <div class="main-table-block">
        <list-heading>
          <list-title>{{ $t('statistics.title') }}</list-title>
        </list-heading>

        <div class="admin-layout__list-filters mb-25px">
          <a-form-item :label="$t('basics.organization')">
            <a-select
                size="large"
                class="w-300px"
                :placeholder="$t('organizations.select_from_list')"
                @change="selectedOrganization = $event"
            >
              <a-select-option v-for="organization in organizationsList" :key="organization.id">
                {{ organization.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('basics.base')">
            <a-select
                size="large"
                class="w-300px"
                :value="selectedBase"
                :placeholder="$t('organizations.select_from_list')"
                :disabled="!selectedOrganization"
                @change="selectedBase = $event"
            >
              <a-select-option :value="0">All bases</a-select-option>
              <a-select-option v-for="base in organizationBasesList" :value="base.id" :key="base.id">
                {{ base.base_id }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('basics.type')">
            <a-select
                size="large"
                class="w-300px"
                :value="selectedType"
                :disabled="!selectedOrganization"
                @change="selectedType = $event"
            >
              <a-select-option v-for="type in statisticTypes" :key="type">
                {{ $t(`statistics.type.${type}`) }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('basics.date_range')">
            <a-range-picker size="large" @change="onChange"/>
          </a-form-item>
          <a-button
              type="primary"
              style="margin-top: 15px;"
              :disabled="!selectedOrganization"
              @click="loadStats"
          >
            {{ $t('statistics.show') }}
          </a-button>
        </div>

        <div v-if="activeLoadedType === 'general'" class="detail-form">
          <div class="detail-info__column-title">{{ $t('statistics.general.title') }}</div>
          <a-row>
            <div v-if="!Object.keys(generalStats).length" class="column-title large-item">{{ $t('statistics.no_data') }}</div>
            <a-col v-for="(stats, idx) in chunkedGeneralStats" :key="idx" :lg="12">
              <p v-for="(value,label) in stats" :key="label">
                <span class="tech-title">{{ $t(`statistics.general.${label}`) }}: </span>
                {{label === 'avg_peak_hour' ? getClientTime(value) : value}}
              </p>
            </a-col>
          </a-row>
        </div>
        <div v-if="activeLoadedType === 'users'" class="detail-form">
          <a-row>
            <a-col :lg="6">
              <div class="detail-info__column-title" style="margin-bottom: 10px;">{{ $t('statistics.users.time_title') }}</div>
              <p v-for="(value,label) in timeStats" :key="label">
                <span class="tech-title">{{ $t(`statistics.time.${label}`) }}: </span>
                {{value}}
              </p>
            </a-col>
            <a-col :lg="18">
              <div class="detail-info__column-title">{{ $t('statistics.users.title') }}</div>
              <a-table
                  :locale={emptyText:emptyText}
                  class="main-table"
                  :columns="userColumns"
                  rowKey="user_id"
                  :pagination="false"
                  :data-source="sortedUsersStats"
                  :scroll="{y: 'calc(100vh - 400px)'}"
                  @change="onUsersTableChange"
              />
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {i18n} from '@/i18n';
import {mapGetters} from 'vuex';
import ListTitle from '@/components/ListTitle/ListTitle'
import ListHeading from '@/components/ListHeading/ListHeading'
import moment from "moment"

const userColumns = [
  {
    title: i18n.t('statistics.users.user_id'),
    dataIndex: 'user_id',
    key: 'user_id',
    sorter: true,
    scopedSlots: { customRender: 'user_id' },
  },
  {
    title: i18n.t('statistics.users.operations_count'),
    dataIndex: 'operations_count',
    key: 'operations_count',
    sorter: true,
    scopedSlots: { customRender: 'operations_count' },
  },
  {
    title: i18n.t('statistics.users.total_hours_use'),
    dataIndex: 'total_hours_use',
    key: 'total_hours_use',
    sorter: true,
    customRender: (value) => {
      if (value >= 60) {
        let hours = Math.floor(value / 60);
        let minutes = value % 60;

        return hours+'h '+minutes+'m';
      }
      return value+'m';
    }
  },
  {
    title: i18n.t('statistics.users.max_use'),
    dataIndex: 'max_use',
    key: 'max_use',
    sorter: true,
    customRender: (value) => {
      if (value >= 60) {
        let hours = Math.floor(value / 60);
        let minutes = value % 60;

        return hours+'h '+minutes+'m';
      }
      return value+'m';
    }
  }
];

export default {
  name: 'Statistics',
  components: {
    ListHeading,
    ListTitle
  },
  data: () => ({
    selectedOrganization: null,
    selectedBase: 0,
    selectedType: 'general',
    selectedDate: {},
    statisticTypes: ['general', 'users'],
    activeLoadedType: null,
    emptyText: i18n.t('statistics.no_data'),
    userColumns,
    sortParams: {}
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  beforeMount() {
    this.$store.dispatch('organizations/GetOrganizationDropdownList');
  },
  computed: {
    ...mapGetters({
      me: 'me',
      generalStats: 'statistics/generalStats',
      usersStats: 'statistics/usersStats',
      timeStats: 'statistics/timeStats',
    }),
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    },
    chunkedGeneralStats() {
      let values = Object.values(this.generalStats);
      let final = [];
      let counter = 0;
      let portion = {};
      const chunkSize = Math.ceil(values.length / 2);

      for (let key in this.generalStats) {
        if (counter !== 0 && counter % chunkSize === 0) {
          final.push(portion);
          portion = {};
        }
        portion[key] = values[counter];
        counter++
      }
      final.push(portion);

      return final;
    },
    organizationsList() {
      let orgList = []
      this.$store.getters['organizations/getOrganizationsWithoutLink'].map(el => {
        if (this.getRole === 1 || el.statistic) {
          orgList.push({...el, value: el.id});
        }
      })
     return orgList;
    },
    organizationBasesList() {
      return this.$store.getters['bases/getOrganizationBasesList'].map(el => {
        return {...el, value: el.id};
      })
    },
    sortedUsersStats() {
      let arr = this.usersStats;

      return Object.keys(this.sortParams).length
          ? arr.sort((a, b) => {
            if (this.sortParams.sortOrder === 'asc') {
              return a[this.sortParams.sortField] - b[this.sortParams.sortField];
            } else {
              return b[this.sortParams.sortField] - a[this.sortParams.sortField];
            }
          })
          : arr.sort((a, b) => b['operations_count'] - a['operations_count']);
    }
  },
  watch: {
    selectedOrganization(id) {
      this.selectedBase = 0;
      if (id) {
        this.$store.dispatch('bases/GetOrganizationBasesList', id);
      }
    }
  },
  methods: {
    loadStats() {
      let params = {};

      if (this.selectedBase) {
        params.base_id = this.selectedBase;
      }
      if (this.selectedDate.from && this.selectedDate.to) {
        params.date = this.selectedDate;
      }

      let payload = {organizationId: this.selectedOrganization, params};

      if (this.selectedType === 'general') {
        this.$store.dispatch('statistics/GetGeneralStats', payload);
      }
      if (this.selectedType === 'users') {
        this.$store.dispatch('statistics/GetUsersStats', payload);
        this.$store.dispatch('statistics/GetTimeStats', payload);
      }
      
      this.activeLoadedType = this.selectedType;
    },
    onChange(_, date) {
      this.selectedDate.from = date[0];
      this.selectedDate.to = date[1];
    },
    onUsersTableChange(pagination, filters, sorter) {
      let sortOrderName = sorter.order === 'ascend' ? 'asc' : 'desc',
          sortParams = {sortField: sorter.columnKey, sortOrder: sortOrderName};

      this.sortParams = sorter.order ? sortParams : {}
    },
    getClientTime(time) {
      if (!time) return null;
      // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dateTime = new Date(Date.parse(time));
      return dateTime.getHours() + ":00"
    }
  }
}
</script>

<style lang="less" scoped>
.w-300px {
  width: 300px;
}
.mb-25px {
  margin-bottom: 25px;
}
.tech-title {
  opacity:0.5;
}

.ant-calendar-picker-large::v-deep {
  .ant-calendar-range-picker-input {
    font-size: 13px;
  }

  .ant-calendar-range-picker-separator {
    line-height: 26px;
  }

  .ant-calendar-picker-clear {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
}
</style>